
.w-container {
    width: min(1022px, 100vw);
    margin: auto;
}

@media (max-width: 1028px) {
    .w-container, .m-container {
        width: calc(100vw - 32px);
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

}