

.qui-est-aeco {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}


.qui-est-aeco p.description {
    max-width: 600px;
    margin: auto;
}

.qui-est-aeco .cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 64px;
    gap: 16px;
}

.qui-est-aeco .cards .card {
    overflow: hidden;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: auto;
}

@media (max-width: 768px) {
    .qui-est-aeco .cards {
        flex-direction: column;
    }
}

.qui-est-aeco .cards .card * {
    color: var(--white);

}

.qui-est-aeco .cards .card .content {
    max-width: 400px;
}

.qui-est-aeco .cards .card .content ul {
    text-align: left;
}

/* avis clients */

.avis-clients {
    margin-top: 80px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.avis-clients p.description {
    max-width: 600px;
    margin: auto;
}

.avis-clients .carousel-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 64px;
    gap: 16px;
    margin-left: auto;
    margin-right: auto;
    max-width: 90vw;

}

.avis-clients .carousel-group .carousel-item {
    border: 1px solid var(--gold);
    padding: 24px;
    position: relative;
    width: 100%;
    /*min-height: 300px;*/
    /*height: 100%;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    align-items: center;
    margin-bottom: 64px;
}

.avis-clients .carousel-group .carousel-item .author {
    font-weight: bold;
}

.avis-clients .carousel-group .carousel-item .role {
    font-style: italic;
}

.avis-clients .carousel .control-dots .dot {
    background-color: var(--gold);
    width: 12px;
    height: 12px;
    box-shadow: unset;
    border: 1px solid var(--gold);
}

.footer-container {
    background-color: var(--light-black);
    min-height: 32px;
}

footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 48px 0;
}

@media (max-width: 768px) {
    footer {
        flex-direction: column;
        gap: 32px;
    }

    footer .contact {
        width: 100%;
    }

    footer .logos {
        /*flex-direction: row !important;*/
        justify-content: space-between;
        width: 100%;
        align-self: flex-start;
    }

    footer .logos .logo {
        text-align: start !important;
    }
}

footer .contact {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 1em;
}

footer .contact .contact-info {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

footer .contact * {
    color: var(--white);
    margin: 0;
}

footer .logos {
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: right;
}

footer .logos * {
    color: var(--white);
}

footer .logos img {
    height: 48px;
}