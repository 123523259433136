h2 {
	font-size: 32px;
}

/*prestations*/

.prestations {
	margin-top: 80px;
	margin-bottom: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.prestations p.description {
	max-width: 600px;
	margin: auto;
}

.prestations .cards {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 64px;
	gap: 16px;
}

@media (max-width: 768px) {
	.prestations .cards {
		flex-direction: column;
	}
}

.prestations .cards .card.nettoyage {
	background:
		linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
		url('../../public/comparisons/nettoyage-splash.jpg') no-repeat;
	background-size: cover;
}

.prestations .cards .card.travaux {
	background:
		linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
		url('../../public/comparisons/amenagement/image-5.jpeg') no-repeat;
	background-size: cover;
}

.prestations .cards .card {
	overflow: hidden;
	position: relative;
	background:
		linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
		url('../../public/comparisons/nettoyage-splash.jpg') no-repeat;
	background-size: cover;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.65);
	width: 100%;
	height: 264px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	align-items: center;
	margin: auto;
}

.prestations .cards .card * {
	color: var(--white);
}

.prestations .cards .card img {
	/*max-height: 264px;*/
	max-width: 100%;
	max-height: 100%;
	object-fit: fill;
	position: absolute;
	z-index: -1;
}

.prestations .cards .card .content {
	max-width: 80%;
}

.prestations .cards .card .content a {
	display: inline-block;
	text-decoration: none;
	color: var(--white);
	margin-top: 16px;
	font-size: 16px;
	padding: 8px 24px;
	background: rgba(0, 0, 0, 0.25);
	border: 1px solid var(--white);
}

.prestations .cards .card .content a:hover {
	cursor: pointer;
	background: rgba(0, 0, 0, 0.5);
}

/*qui est aeco*/

.gold-container {
	background-color: var(--gold);
	padding: 80px 0;
}

.gold-container * {
	color: var(--white);
}

.black-container {
	background-color: #262828;
	padding: 80px 0;
}

.black-container * {
	color: var(--white);
}

.red-container {
	background-color: #7f242c;
	padding: 80px 0;
}

.red-container * {
	color: var(--white);
}
