.question-accordion {
    border: 1px solid var(--gold);
    padding: 0 16px;
    margin: 0 0 16px 0;
    width: calc(100% - 32px);
}

.question-accordion .question-lead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    cursor: pointer;
}

.question-accordion .question-lead.no-content {
    cursor: default;
}

.question-accordion .question-lead .question {
    color: var(--gold);
}

.question-accordion .question-lead .icon * {
    color: var(--gold);
}

.question-accordion .question-answer {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.question-accordion .question-answer p {
    padding: 0;
}