.contact-section {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin: 80px auto;
}

@media  (max-width: 768px){
    .contact-section {
        flex-direction: column;
    }
}

.contact-container {
    display: flex;
    flex-direction: column;
}

.contact-section > * {
    flex: 1;
}

.contact-container h2 {
    margin: 0;
}

.contact-section .contact-container .infos {
    margin-top: auto;
    display:flex;
    flex-direction: column;
    gap: 8px;
}

.contact-section .contact-container .infos .contact-info {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    margin-bottom: 4px;
}

.contact-section .contact-container .infos .contact-info p {
    margin: 0;
}


.contact-section .contact-container .infos p {
    margin: 0;
}