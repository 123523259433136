.comparison-image {
    width: 100%;
    position: relative;
}

.comparison-image-container {
    position: relative;
    max-width: 300px;
}

.comparison-image .comparison-image-container .before-image-container {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 100%;
    max-width: 300px;
    transition: opacity 0.3s ease-in-out;
    border-right: 2px solid var(--gold);
    z-index: 2;
}

.comparison-image .comparison-image-container .before-image, .comparison-image .comparison-image-container .after-image {
    height: 100%;
    /*max-width: 300px;*/
    display: block;
    object-fit: cover;
    width: 300px;
}

.comparison-image .comparison-image-container .after-image-container {
    height: 100%;
    max-width: 300px;
    display: block;
}

.comparison-image .comparison-image-container .before-image-container .label-container {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}

.comparison-image .comparison-image-container .before-image-container .label-container .label {
    padding: 8px 16px;
    background-color: var(--gold);
    color: var(--white);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
}


.comparison-image .comparison-image-container .after-image-container .label-container {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
}

.comparison-image .comparison-image-container .after-image-container .label-container .label {
    padding: 8px 16px;
    background-color: var(--gold);
    color: var(--white);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    cursor: pointer;
}