.btn {
	border: 1px solid var(--gold);
	padding: 24px 16px;
	color: var(--gold);
	background-color: var(--white);
	cursor: pointer;
	font-weight: bold;
	text-decoration: none;
	transition: all 0.2s ease-in-out;
}

.btn:hover {
	background-color: var(--gold);
	color: var(--white);
}

.service-hero {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.service-hero h2 {
	margin-top: 0;
}

.service-hero h2.title {
	margin-top: 30px;
}

.service-hero p.description {
	max-width: 600px;
	margin: auto;
}

.services {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 80px 0 40px 0;
}

.services p.description {
	max-width: 600px;
	margin: auto;
	padding-bottom: 40px;
}

.services .questions {
	margin: 80px 0 0 0;
	width: 100%;
}

.exemples {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 40px 0 80px 0;
}

.exemples p.description {
	max-width: 600px;
	margin: auto;
	padding-bottom: 40px;
}

.exemples .image-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 32px;
	max-width: 100%;
}

@media (max-width: 1024px) {
	.exemples .image-grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 768px) {
	.exemples .image-grid {
		grid-template-columns: repeat(1, 1fr);
	}
}

.exemples .image-grid .image {
	width: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.exemples .image-grid img.image {
	object-fit: cover;
}

.slider-icon path {
	color: white;
}

.video-react {
	max-width: 300px;
}
