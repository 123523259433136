header {
    background-color: var(--white);
    height: 72px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 8px;
}

header nav .desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

header nav .mobile {
    display: none;
}

header nav .mobile svg {
    width: 24px;
    height: 24px;
}

header nav .mobile path {
    fill: var(--gold);
}

@media (max-width: 768px) {
    header nav .desktop {
        display: none;
    }

    header nav .mobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
    }
}

header nav .mobile .menu {
    display: block;
    position: absolute;
    background-color: var(--gold);
    top: 0;
    left: 100%;
    width: 0;
    height: 100vh;
    transition: left 0.3s ease-in-out;
    z-index: 999;
}

header nav .mobile .menu * {
    color: white;
    fill: white;
    display: none;
}

header nav .mobile .menu.active {
    width: 100vw;
    left: 0;
}

header nav .mobile .menu.active * {
    display: block;
}

header nav .mobile .menu .menu-close {
    position: absolute;
    top: 32px;
    right: 16px;
}

header nav .mobile .menu .menu-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    height: 100%;
}

header nav a {
    all: unset;
    color: var(--black);
}

header nav a:hover {
    cursor: pointer;
    text-decoration: underline;
}

header nav a.active {
    color: var(--gold);
}