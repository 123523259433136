.hero {
	max-height: 360px;
}

.hero img {
	position: absolute;
	object-fit: cover;
	object-position: 0% 45%;
	width: 100%;
	height: 360px;
}

.hero .overlay {
	min-height: 360px;
	backdrop-filter: blur(1px);
}

.hero-body {
	height: 100%;
	margin-left: 0;
	min-height: 360px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 6px;
	max-width: 400px;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50));
	padding-left: 30px;
	padding-right: 30px;
}

.hero-body h2 {
	max-width: 350px;
}

.hero-body * {
	margin: 0;
	color: var(--white);
	text-shadow: 0px 4px 9px #000000;
}

.contact-button {
	display: inline-block;
	text-decoration: none;
	color: var(--white);
	margin-top: 16px;
	font-size: 16px;
	padding: 8px 24px;
	background: rgba(0, 0, 0, 0.25);
	border: 1px solid var(--white);
}